import { on, closest, findAllIn, hasClass } from '@elements/dom-utils';
import { onFind } from '@elements/init-modules-in-scope';

const selectors = {
    textSearchInput: `input[type="text"][data-event-tracking-references-key].js-event-tracking-monitor-value, input[type="search"][data-event-tracking-references-key].js-event-tracking-monitor-value`,
    radioInput: `input[type="radio"].js-event-tracking-monitor-value`,
    navbarQuicklinks: '.js-quicklinks-navbar-wrapper',
    subnavAnchor: '.js-event-tracking-subnav-anchor',
    trackAjaxForm: {
        form: '.js-event-tracking-ajax-form',
        textInput: 'input.js-event-tracking-monitor-value',
        selectInput: 'select.js-event-tracking-monitor-value'
    },
    accessoryAdvisorLink: '.js-event-tracking-accessory-advisor-link',
    trackTeaser: '.js-event-tracking-teaser',
    teaserLink: '.js-event-tracking-teaser-link',
    formBuilderFormtype: '.js-event-tracking-ajax-formtype',
    selectInput: 'select.js-event-tracking-monitor-value',
    productDetail: {
        variantSelector: 'select.js-event-tracking-monitor-value.js-event-tracking-on-product-details',
        tabbingWrapper: '.js-event-tracking-tabbing',
        tabbingTab: '.js-event-tracking-tab-item'
    }
}

export function init() {
    initBasicInputsTracking()
    initAnchorTracking()
    initMultipleInputsAjaxformTracking()
    initProductDetailTracking()
    initAccessoryAdvisorTracking()
    initTeaserTracking()
    initFormBuilderTracking()
}

function initBasicInputsTracking() {
    initTextinputs()
    initRadioTracking()
    initSelectsTracking() // for all selects except dealer & service search

    function initTextinputs() {
        onFind(selectors.textSearchInput, (elem) => {
            on("input", (event) => {
                _trackingData[elem.dataset.eventTrackingReferencesKey].datalayer.search_term = elem.value
            }, elem)
        })
    }

    function initRadioTracking() {
        onFind(selectors.radioInput, (elem) => {
            updateTopic()

            on("input", (event) => {
                updateTopic()
            }, elem)

            function updateTopic() {
                if(elem.checked === true) {
                    _trackingData[elem.dataset.eventTrackingReferencesKey].datalayer.topic = elem.value

                    pushToDatalayer({
                        'event': 'newsletter_pick_topic',
                        'topic': elem.value
                    })
                }
            }
        })
    }

    function initSelectsTracking() {
        // for product grid selects, the data attributes have to be added manually (= adaptive fields).

        onFind(selectors.selectInput, (select) => {
            // product detail selects get handled differently in initProductDetailTracking():
            if(hasClass("js-event-tracking-on-product-details", select) || hasClass("js-event-tracking-on-dealerservice", select)) return

            on('change', () => {
                if(!hasClass('js-event-tracking-adaptive-fields', select)) {
                    pushToDatalayer({
                        'event': 'select_filter',
                        'filter_type': getSelectLabel(select),
                        'filter_value': toSnakeCase(removeParenthesesAndNumbers(select.options[select.selectedIndex].label))
                    })
                }

                else {
                    // used in newsroom filter, for example
                    _trackingData[select.dataset.eventTrackingReferencesKey].datalayer[select.dataset.eventTrackingName] = toSnakeCase(select.options[select.selectedIndex].label)
                }
            }, select)

            function removeParenthesesAndNumbers(inputString) {
                console.log("before remove of num and parantheses: ", inputString)
                return inputString.replace(/\s*\([^)]*\)\s*/g, '');
            }
        })
    }
}

function initAnchorTracking() {
    onFind(selectors.subnavAnchor, (elem) => {
        if(elem.hash.length > 0) {
            on('click', () => {
                pushToDatalayer({
                    'event': 'click_anchor_link',
                    'click_item': elem.hash.replace('#', ''),
                    'click_page_location': `${window.location.pathname}${elem.hash}`
                })
            }, elem)
        }
    })
}

function initMultipleInputsAjaxformTracking() {
    const InputTypes = {
        None: Symbol("none"),
        Select: Symbol("select"),
        Search: Symbol("search-text-input"),
    }

    let lastUsedInputType = InputTypes.None
    let lastUsedSelect = undefined

    onFind(selectors.trackAjaxForm.form, (form) => {
        // this has 2 fields that auto submit the form on change - but the tracking event should only contain one field
        // -> assess, which field was changed last and the send the event on ajax-submit

        if(form.dataset.eventTrackingAjaxFormCase == "service-dealers") {
            let lastChangedInputName = ""
            let lastChangedInputValue = ""

            // all text inputs (serach fields)
            findAllIn(selectors.trackAjaxForm.textInput, form).forEach((input) => {
                on("input", () => {
                    lastUsedInputType = InputTypes.Search
                    lastChangedInputName = input.dataset.eventTrackingName
                    lastChangedInputValue = input.value
                }, input)
            })

            // all selects
            findAllIn(selectors.trackAjaxForm.selectInput, form).forEach((select) => {
                on('change', () => {
                    lastUsedInputType = InputTypes.Select
                    lastUsedSelect = select
                    lastChangedInputName = 'select_filter'
                    lastChangedInputValue = toSnakeCase(select.options[select.selectedIndex].label)
                }, select)
            })

            on("fetched.ajax-form", () => {
                switch (lastUsedInputType) {
                    case InputTypes.Search:
                        pushToDatalayer({
                            'event': toSnakeCase(lastChangedInputName),
                            'search_term' : toSnakeCase(lastChangedInputValue)
                        })
                        break;

                    case InputTypes.Select:
                        pushToDatalayer({
                            'event': toSnakeCase(lastChangedInputName),
                            'filter_type': getSelectLabel(lastUsedSelect),
                            'filter_value' : toSnakeCase(lastChangedInputValue)
                        })
                        break;

                    default:
                        console.warn("no input was made yet - tracking nothing")
                }
            }, form)
        }
    })
}

function initAccessoryAdvisorTracking() {
    // to prevent a second info event when clicking on the link
    onFind(selectors.accessoryAdvisorLink, (elem) => {
        on("click", (e) => {
            e.stopPropagation()
        }, elem)
    })
}

function initTeaserTracking() {
    onFind(selectors.trackTeaser, (teaser) => {
        findAllIn(selectors.teaserLink, teaser).forEach((link) => {
            on("click", () => {
                 pushToDatalayer({
                    'event': 'click_teaser',
                    'click_item': toSnakeCase(teaser.dataset.eventTrackingTeaserTitle),
                    'click_page_location': new URL(link.href).pathname
                })
            }, link)
        })
    })
}

// takes the value of hidden input that corresponds to the chosen form type in the form builder and saves it into the datalayer of the form
function initFormBuilderTracking() {
    onFind(selectors.formBuilderFormtype, (hiddeninput) => {
        const belongsToForm = closest(`form[data-event-tracking-key]`, hiddeninput)
        _trackingData[belongsToForm.dataset.eventTrackingKey].datalayer.form_type = hiddeninput.value
    })
}

function initProductDetailTracking() {
    initProductDetailSelectTracking()
    initProductDetailTabbingTracking()

    function initProductDetailSelectTracking() {
        onFind(selectors.selectInput, (select) => {
            if(hasClass("js-event-tracking-on-product-details", select) === undefined) return

            on('change', () => {
                if(select.dataset.eventTrackingProductTitle !== undefined) {
                    pushToDatalayer({
                        'event': 'select_product_variant',
                        'product_name': toSnakeCase(select.dataset.eventTrackingProductTitle),
                        'product_variant':  toSnakeCase(select.options[select.selectedIndex].label)
                    })
                }
            }, select)
        })
    }
    function initProductDetailTabbingTracking() {
        let productVariantName = ""
        let productTitle = ""

        onFind(selectors.productDetail.variantSelector, (variantSelector) => productVariantName = toSnakeCase(variantSelector.options[variantSelector.selectedIndex].label))
        onFind(selectors.productDetail.tabbingWrapper, (tabbingWrapper) => productTitle = toSnakeCase(tabbingWrapper.dataset.eventTrackingProductName))

        onFind(selectors.productDetail.tabbingTab, (tab) => {
            on('click', () => {
                pushToDatalayer({
                    'event': 'click_tab',
                    'product_name': productTitle,
                    'product_variant':  productVariantName,
                    'click_item': toSnakeCase(tab.dataset.eventTrackingTabName !== undefined ? tab.dataset.eventTrackingTabName : '')
                })
            }, tab)
        })
    }
}

function toSnakeCase(input) {
    return input?.toLowerCase().replaceAll(" ", "_")
}

function getSelectLabel(select) {
    return select.dataset.eventTrackingName !== undefined ? toSnakeCase(select.dataset.eventTrackingName) : ''
}

function pushToDatalayer(data) {
    console.table(data)
    window.dataLayer.push(data)
}