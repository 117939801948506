/* scripts for navigation */
import * as nav from './nav';
nav.init();

import * as affixNav from './affix-nav';
affixNav.init();

import {onFind} from "@elements/init-modules-in-scope";
import Collapse from 'bootstrap/js/dist/collapse';
onFind('[data-toggle="collapse"]',function (element) {
    new Collapse(element);
});

import Dropdown from 'bootstrap/js/dist/dropdown';
onFind('[data-toggle="dropdown"]',function (element) {
    new Dropdown(element);
});

import * as actionChanger from '@elements/action-changer';
actionChanger.init();