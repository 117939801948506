import throwError from '@elements/throw-error';
import {on} from '@elements/dom-utils';

export function cookieConsentCallback(serviceName, callback) {
    if (!serviceName || !callback) {
        throwError('cookieConsentCallback: wrong arguments serviceName:String, callback:Function');
        return;
    }
    let onChangeListening = false;

    const originalWrapper = window.OptanonWrapper;

    if (originalWrapper) {
        window.OptanonWrapper = () => {
            originalWrapper();

            if(window.OneTrust && !onChangeListening) {
                onChangeListening = true;
                if (window.OnetrustActiveGroups.indexOf(serviceName) != -1) {
                    try {
                        callback();
                    } catch (error) {
                        console.error(error);
                    }
                } else {
                    window.OneTrust.OnConsentChanged(() => {
                        console.log("cookie consent changed");
                        if (window.OnetrustActiveGroups.indexOf(serviceName) != -1) {
                            try {
                                callback();
                            } catch (error) {
                                console.error(error);
                            }
                        }
                    })
                }
            }
        }
    }
}
