"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {removeClass, addClass, find} from '@elements/dom-utils';

const selectors = {
    base: '.js-affix-nav'
};

const isMobile = () => matchMedia('(max-width: 767px)').matches;

let lastScrollPosition = 0,
    isRunning = false,
    topOffset = 0,
    nav;

export function init () {
    if (!isMobile) {
        return;
    }


    onFind(selectors.base, function (element) {
        nav = element;
        setupAffix();
    });


    function setupAffix() {
        if(!isRunning) {
            isRunning = true;
            requestAnimationFrame(interval);
        }
    }

    function interval() {
        if (lastScrollPosition === window.scrollY) {
            requestAnimationFrame(interval);
            return;
        }

        updateDom();
        lastScrollPosition = window.scrollY;
        requestAnimationFrame(interval);
    }


    function updateDom() {
        nav.isAffix = lastScrollPosition > window.scrollY;

        let setDefault = lastScrollPosition < topOffset;
        topOffset = nav.offsetHeight;


        if (nav && nav.isAffix && !setDefault) {
            setAffix(nav);
        } else {
            if(!setDefault) {
                unsetAffix(nav);
            } else {
                setDefaultPosition(nav);
            }
        }
    }

}


export function setAffix(nav) {
    addClass('is-affix', nav);
    removeClass('not-is-affix', nav);
}

export function unsetAffix(nav) {
    removeClass('is-affix', nav);
    addClass('not-is-affix', nav);
}

export function setDefaultPosition(nav) {
    removeClass('is-affix', nav);
    removeClass('not-is-affix', nav);
}
