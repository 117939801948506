import {onFind} from "@elements/init-modules-in-scope";

import * as toc from '@elements/toc';
toc.init({linkClass: 'visually-hidden-focusable'});

import * as lazyImg from '@elements/lazy-img';
lazyImg.init();

import Dropdown from 'bootstrap/js/dist/dropdown';
onFind('[data-bs-toggle="dropdown"]',function (element) {
    new Dropdown(element);
});

import * as renderTemplate from './render-template';
renderTemplate.init();

import * as floatingLabels from '@elements/floating-labels';
floatingLabels.init();

import * as formValidation from './formValidation';
formValidation.init();

import * as tableResponsive from './table-responsive';
tableResponsive.init();

import * as allowCookieConsent from './allow-cookie-consent';
allowCookieConsent.init();

import * as tracking from "@elements/tracking";
let options = { debug: false }; // optional options
tracking.initGtmEventTracking(options); // for GTM tracking

import * as trackingComponents from "./tracking"
trackingComponents.init()

import * as skipLinks from '@elements/skip-links';
skipLinks.init();
console.log('skipLinks.init()');

// Todo: Add common JS that is shared between all page types here