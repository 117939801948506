import {onFind} from "@elements/init-modules-in-scope";
import {on, triggerWith} from '@elements/dom-utils';
import {cookieConsentCallback} from "js/scripts/cookie-consent-callback";

const defaultSelectors = {
    base: '.js-allow-cookie-consent',
};

export function init(selectors = defaultSelectors) {
    onFind(selectors.base, function (baseElement) {
        let cookieServiceName = baseElement.getAttribute('data-allow-cookie-consent');
        const originalWrapper = window.OptanonWrapper;
        let onChangeListening = false;

        if (originalWrapper) {
            window.OptanonWrapper = () => {
                originalWrapper();

                if (window.OneTrust) {
                    if (window.OnetrustActiveGroups.indexOf(cookieServiceName) === -1) {
                        baseElement.removeAttribute('hidden');
                    }
                    window.OneTrust.OnConsentChanged(() => {
                        if (window.OnetrustActiveGroups.indexOf(cookieServiceName) != -1) {
                            baseElement.setAttribute('hidden', true);
                        }
                    })
                }

                // on('click', function() {
                //     if(window.OneTrust && cookieServiceName) {
                //         if (window.OnetrustActiveGroups.indexOf(cookieServiceName) === -1) {
                //             OneTrust.ToggleInfoDisplay();
                //             // OneTrust.UpdateConsent("Category", cookieServiceName + ":1");
                //             // OneTrust.Close();
                //             // baseElement.setAttribute('hidden', true);
                //             // triggerWith('allowed-cookie-consent', cookieServiceName, document.body);
                //         } else {
                //             baseElement.setAttribute('hidden', true);
                //         }
                //     }
                // }, baseElement);
            }
        }

    },document);
}
