"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import Pristine from "../libs/pristine/pristine.js";
import {on, findAllIn, findAll, findIn, hasClass, closest, addClass, removeClass, matches} from "@elements/dom-utils";
import {translate} from '@elements/translations';

const defaultOptions = {
    classTo: 'form-group',
    errorClass: 'has-error',
    successClass: 'has-success',
    errorTextParent: 'form-group',
    // type of element to create for the error text
    errorTextTag: 'div',
    // class of the error text element
    errorTextClass: 'form-errors invalid-feedback'
};

const defaultSelectors = {
    base: '.js-form-validation'
};

export function init(options = defaultOptions, selectors = defaultSelectors) {
    onFind(selectors.base, function (baseElement) {
        createFormValidation(
            baseElement,
            {...defaultOptions, ...options},
            {...defaultSelectors, ...selectors}
        );
    });
}

export function createFormValidation(form, options, selectors) {
    Pristine.setLocale('en');

    if(!form.pristine) {
        const pristine = new Pristine(form, options);

        initializeMaxFileSizeValidator(pristine);

        if(form.getAttribute('data-form-validation-with-disabled-submit')) {
            toggleSubmitButtonClass(form, pristine);

            on('change', function() {
                toggleSubmitButtonClass(form, pristine);
            }, form);
        }

        form.addEventListener('submit', function (e) {
            if (!pristine.validate()) {
                e.preventDefault();
                findIn(".form-control", findIn(".has-error", form))?.focus();
            }
        });
    }
}

export function initializeMaxFileSizeValidator(pristine) {
    let maxFileSizeInputs = document.querySelectorAll('[data-pristine-max-file-size]');
    maxFileSizeInputs.forEach(input => {
        let maxFileSize = input.getAttribute('data-pristine-max-file-size') || false;
        let errorMessage = translate("form-validation.The file size must be") + " < " + maxFileSize + " MB";
        if(maxFileSize) {
            pristine.addValidator(input, function (value, param1) {
                if(!input.files[0]){
                    return true;
                }

                return input.files[0].size < maxFileSize*1000000;
            },  errorMessage, 1, true);
        }
    })
}

export function isValid(form) {
    if (matches('form', form)) {
        const pristine = new Pristine(form, defaultOptions);
        return pristine.validate();
    } else {
        return 'Using pristine without form';
    }
}

export function isValidInput(form, inputs) {
    if(matches('form', form)){
        const pristine = new Pristine(form, defaultOptions);
        return pristine.validate(inputs, true);
    }else{
        return 'Using pristine without form';
    }
}


function toggleSubmitButtonClass(form, pristine) {
    let formElements = form.querySelectorAll('input:not([type="hidden"]), select');
    let wrapper = closest('.js-form-validation__wrapper', form);
    let submitButtons = findAllIn('[type="submit"]', wrapper);

    console.log("toggle submit button class");
    if(!pristine.validate(formElements, true)) {
        submitButtons.map(function(btn) {
            if(!hasClass('js-form-validation__submit-ignore-validation-style', btn)) {
                addClass('form-validation-not-valid', btn) ;
            }
        });
    } else {
        submitButtons.map(function(btn) {
            removeClass('form-validation-not-valid', btn) ;
        });
    }
}

export function isValidFormValidation(form) {
    if (hasClass('js-form-validation', form)) {
        if(form.pristine){
            return form.pristine.validate();
        }else{
            let pristine = new Pristine(form, defaultOptions);
            initializeMaxFileSizeValidator(pristine);
            return pristine.validate();
        }
    }

    return true;
}